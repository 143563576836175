import axios from "axios";
import store from '@/store'
import Config from "@/services/Config.js"
import { mapKeys, mapValues, camelCase, snakeCase } from "lodash";

const { VUE_APP_API_BASIC,VUE_APP_API_URL } = process.env;

function transformKeys(data, iteratee) {
  if (Array.isArray(data)) {
    return data.map((d) => transformKeys(d, iteratee));
  }

  if (data instanceof Object) {
    return mapValues(
      mapKeys(data, (_, k) => iteratee(k)),
      (v) => transformKeys(v, iteratee)
    );
  }

  return data;
}

export function camelCaseKeys(data) {
  return transformKeys(data, camelCase);
}

export function snakeCaseKeys(data) {
  return transformKeys(data, snakeCase);
}

// @see: https://github.com/mzabriskie/axios#axios-api
export function request(method, url, config = {}, options = {}) {
  const {
    params,
    data,
    headers,
    maxContentLength
  } = config;

  // non-axios specific params
  const {
    suppressAuth
  } = options;

  const baseURL = Config.checkDomain();
  const BasicAuth = VUE_APP_API_BASIC;
  
  // @see: https://tools.ietf.org/html/rfc6750
  const basicAuth = `Basic ${BasicAuth}`;
  const slug = `${localStorage.getItem('slug')}`

  axios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
  
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (error.response) {
        return error.response;
        // if (error.response.status == 401) {
        //   console.log("401")

        //   return;
        // } else if (error.response.status == 404) {
        //   // If request 404 (notfound)
        //   console.log("404")

        //   return;
        // } else {
        //   // Handle if status outside 401, and 404
        //   console.log("500")

        //   return;
        // }
      } else {
        console.log("error tidak diketahui")

        return;
      }
    }
  );

  return new Promise((resolve, reject) => {
    axios({
      method,
      baseURL,
      url,
      params,
      timeout: 20000,
      data: data,
      headers: suppressAuth ? headers : { ...headers, Authorization: basicAuth},
      maxContentLength
    })
      .then((response) => {
        resolve({
          ...response,
          data: response.data
        });
      })
      .catch(reject)
  });
}

export function get(url, config, options) {
  return request("GET", url, config, options);
}

export function post(url, config, options) {
  return request("POST", url, config, options);
}

export function put(url, config, options) {
  return request("PUT", url, config, options);
}

// not "delete()" because of reserved word
export function destroy(url, config, options) {
  return request("DELETE", url, config, options);
}