import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "*",
    redirect: "/404"
  },
  {
    path: "/404",
    name: "404",
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
    meta: {
      title: "404",
      noslug: true
    }
  },
  {
    path: "/",
    name: "index",
    component: () => import(/* webpackChunkName: "index" */ '@/views/Index.vue'),
    meta: {
      title: "Beranda",
      noslug: true
    }
  },
  {
    path: '/:slug',
    name: 'Verify',
    component: () => import(/* webpackChunkName: "verify" */ '@/views/Verify.vue'),
    meta: {
      noslug: true
    }
  },
  {
    path: '/:slug/order',
    name: 'Order',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Order.vue'),
    meta : {
      isNoName: true
    }
  },
  {
    path: '/:slug/menu',
    name: 'Menu',
    component: () => import(/* webpackChunkName: "menu" */ '@/views/Menu.vue'),
    meta : {
      isName: true
    }
  },
  {
    path: '/:slug/cart',
    name: 'Keranjang',
    component:  () => import(/* webpackChunkName: "cart" */ '@/views/Cart.vue'),
    meta : {
      isName: true
    }
  },
  {
    path: '/:slug/thanks',
    name: 'Terimakasih',
    component: () => import(/* webpackChunkName: "thanks" */ '@/views/Thanks.vue'),
    meta: {
      noslug: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // if(from.name == 'index'){
  //   return router.replace({name: "index"});
  // }
  // let customerData = localStorage.getItem('customerData')
  // const isNoName = to.matched.some(record => record.meta.isNoName)
  // const isName = to.matched.some(record => record.meta.isName)
  
  // if(customerData){
  //   customerData = JSON.parse(customerData)
  //   let slug = customerData.sales_order_table_name.replace(" ", "+")
  //   if(customerData.sales_order_customer_name != "" && isNoName){
  //     return next({path:"/order/"+slug+"/menu"})
  //   }

  //   if(customerData.sales_order_customer_name == "" && isName){
  //     return next({path:"/order/"+slug})
  //   }
  // }
  // if(!customerData && (isName || isNoName)){
  //   return next({path:"/"+slug})
  // }

  document.title = `SODA | Self Order`
  next()
})

export default router
