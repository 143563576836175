<template>
  <v-app style="background-color: #f2f5f9">
    <v-main>
      <v-snackbar v-model="snackWithButtons" bottom right timeout="-1">
        {{ snackWithBtnText }}
        <template v-slot:action="{ attrs }">
          <v-btn text color="#00f500" v-bind="attrs" @click.stop="refreshApp">
            {{ snackBtnText }}
          </v-btn>
          <v-btn icon class="ml-4" @click="snackWithButtons = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <router-view />
    </v-main>
    <v-overlay :value="$store.state.overlay" z-index="9999" class="text-center">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <div class="pa-4">{{ $store.state.overlayText }}</div>
    </v-overlay>
  </v-app>
</template>

<script>
import { get, post, put, destroy } from "@/services/Axios";

export default {
  components: {},
  data() {
    return {
      snackWithButtons: false,
      snackWithBtnText: "",
      snackBtnText: "",
      refreshing: false,
      registration: null,
    };
  },
  computed: {},
  watch: {
    $route(to, from) {},
  },
  created() {
    // Listen for swUpdated event and display refresh snackbar as required.
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    // Refresh all open app tabs when a new service worker is installed.
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    }
  },
  methods: {
    showRefreshUI(e) {
      // Display a snackbar inviting the user to refresh/reload the app due
      // to an app update being available.
      // The new service worker is installed, but not yet active.
      // Store the ServiceWorkerRegistration instance for later use.
      this.registration = e.detail;
      this.snackBtnText = "Refresh";
      this.snackWithBtnText = "Versi baru telah tersedia!";
      this.snackWithButtons = true;
    },
    refreshApp() {
      this.snackWithButtons = false;
      // Protect against missing registration.waiting.
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    },
  },
};
</script>

<style>
@media only screen and (min-width: 1024px) {
  .v-app-bar,
  .v-dialog,
  .v-application {
    max-width: 35% !important;
    margin: 0 auto;
  }
}
</style>