import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dialog: {
      confirmation: false,
      error: false
    },
    errorMessage: "",
    cart:{
      data: [],
      totalPrice: 0
    },
    overlay: false,
    overlayText: "",
    slug: ""
  },
  mutations: {
    SET(state, {
      key,
      value
    }) {
      state[key] = value;
    },
  },
  actions: {
  },
  modules: {
  }
})
