const Config = {
  checkDomain : () => {
    let windowLoc = window.location.hostname
    
    if(windowLoc != 'order.sodapos.com'){
      return 'https://api-dev.sodapos.com/self/'
    } else {
      return 'https://api.sodapos.com/self/'
    }
  }
}

export default Config
